import pushToDataLayer from '../js/pushToDataLayer';
const navbar = document.getElementById('masthead');
if (navbar) {
    const navLinks = navbar.querySelectorAll('#site-navigation .menu-item a');
    Array.from(navLinks).forEach(navLink => {
        navLink.addEventListener('click', e => {
            const { dataset } = navLink;
            const event = dataset.gtmEvent || 'Main Navigation';
            const eventCategory = dataset.gtmCategory || dataset.gtmEvent || 'Main Navigation';
            const eventAction = dataset.gtmAction || e.target.textContent.trim();
            const eventLabel = dataset.gtmAction || e.target.textContent.trim();
            pushToDataLayer(e, event, eventCategory, eventAction, eventLabel);
        });
    });

    const utilityNavLinks = navbar.querySelectorAll('.utility-nav a');
    Array.from(utilityNavLinks).forEach(utilityNavLink => {
        utilityNavLink.addEventListener('click', e => {
            const { dataset } = utilityNavLink;
            const event = dataset.gtmEvent || 'Utility Header Links';
            const eventCategory = dataset.gtmCategory || dataset.gtmEvent || 'Utility Header Links';
            const eventAction = dataset.gtmAction || e.target.textContent.trim();
            const eventLabel = dataset.gtmAction || e.target.textContent.trim();
            pushToDataLayer(e, event, eventCategory, eventAction, eventLabel);
        });
    });

    const logo = navbar.querySelector('.custom-logo-link');
    logo.addEventListener('click', e => {
        pushToDataLayer(e, 'Header', 'Header', 'Logo');
    });

    const clickToCall = navbar.querySelector('.tel');
    if(clickToCall) {
        clickToCall.addEventListener('click', e => {
            pushToDataLayer(e, 'Click to Call', 'Header', '678-247-7300');
        });
    }
}


const content = document.getElementById('content');

//const links = content.querySelectorAll('a:not(.btn):not(.stay-informed-link):not([rel="category tag"])');
const signup = content.querySelectorAll('.cpk-signup');
const stayInformed = content.querySelectorAll('.stay-informed-link');
const stayInformedCategory = content.querySelectorAll('.post-categories a');
const insightsSeeMore = content.querySelectorAll('.content-hub-articles .load-more');
const contactSubmitButton = content.querySelectorAll('.cpk-contact');
const contentSearchBtn = document.getElementById('content-search-btn');
const regularSearch = document.getElementById('searchsubmit');
const insightsArticleLink = content.querySelectorAll('.insights-landing-link');
const insightsFilters = content.querySelectorAll('.category-label');
const sidebarContactUs = content.querySelectorAll('.sidebar-primary-btn');
const gatedFormButton = content.querySelectorAll('.gated-form-button');



if (sidebarContactUs) {
    
    Array.from(sidebarContactUs).forEach(element => {
        element.addEventListener('click', e => {
            const { dataset } = element;
            const event = dataset.gtmEvent || 'Sidebar Contact Us';
            const eventCategory = dataset.gtmCategory || dataset.gtmEvent || 'Sidebar Contact Us';
            const eventAction = dataset.gtmAction || e.target.textContent.trim();
            const eventLabel = dataset.gtmLabel || global.location.pathname;
            pushToDataLayer(e, event, eventCategory, eventAction, eventLabel);
        });
    });
}

if($('.content-hub-articles').length) {
    $('.content-hub-articles').on('click', '.insights-landing-link', function(e) {
            const insightsArticleCategory = $(this).find('strong').text();
            const insightsArticleTitle = $(this).find('.article-title').text();
            dataLayer.push({
                "event": 'Insights Hub Articles',
                "eventCategory": 'Insights Hub Articles',
                "eventAction": 'Insights Hub Articles',
                "eventLabel": insightsArticleCategory + ' ~ ' + insightsArticleTitle,
            })
    })
}

if (insightsFilters) {
    
    Array.from(insightsFilters).forEach(element => {

        const checkedInput = element.querySelectorAll('input')[0];
        const checkedInputParentCategory = element.closest('.category-field-group');
        const checkedInputParentCategoryTitle = checkedInputParentCategory.querySelectorAll('h3')[0].textContent;
        checkedInput.addEventListener('click', e => {
            if(checkedInput.checked) {
                const { dataset } = element;
                const event = dataset.gtmEvent || 'Insights Hub Filters';
                const eventCategory = dataset.gtmCategory || dataset.gtmEvent || 'Insights Hub Filters';
                const eventAction = dataset.gtmAction || checkedInputParentCategoryTitle;
                const eventLabel = dataset.gtmLabel || element.textContent;
                pushToDataLayer(e, event, eventCategory, eventAction, eventLabel);
            }
        });
    });
}

if (contentSearchBtn) {
        contentSearchBtn.addEventListener('click', e => {
            const searchInput = document.getElementById('content-search-input').value;
            const { dataset } = contentSearchBtn;
            const event = dataset.gtmEvent || 'Insights Hub Search';
            const eventCategory = dataset.gtmCategory || dataset.gtmEvent || 'Insights Hub Search';
            const eventAction = dataset.gtmAction || e.target.textContent.trim();
            const eventLabel = dataset.gtmLabel || searchInput;
            pushToDataLayer(e, event, eventCategory, eventAction, eventLabel);
        });
}

//Press enter - trigger hug search tracking
/*const contentSearchInputForEnter = document.getElementById("content-search-input");
    contentSearchInputForEnter.addEventListener("keyup", function(event) {
    event.preventDefault();
    if (event.keyCode === 13) {
        contentSearchBtn.click();
    }
});*/

if (regularSearch) {
        regularSearch.addEventListener('click', e => {
            const regularSearchInput = document.getElementById('s').value;
            const { dataset } = regularSearch;
            const event = dataset.gtmEvent || 'Site Search';
            const eventCategory = dataset.gtmCategory || dataset.gtmEvent || 'Site Search';
            const eventAction = dataset.gtmAction || e.target.textContent.trim();
            const eventLabel = dataset.gtmLabel || regularSearchInput;
            pushToDataLayer(e, event, eventCategory, eventAction, eventLabel);
        });
}

//Press enter - trigger regular top search tracking
/*const searchInputForEnter = document.getElementById("s");
    searchInputForEnter.addEventListener("keyup", function(event) {
    event.preventDefault();
    if (event.keyCode === 13) {
        regularSearch.click();
    }
});*/

if (contactSubmitButton) {
    
    Array.from(contactSubmitButton).forEach(element => {
        let market = '';
        let help = '';
        const newsletteroptin = $('#newsletterOptinInput');
        const announcementOptinInput = $('#announcementOptinInput');
        let newsIsChecked = '';
        let announcementIsChecked = '';
        document.addEventListener( 'wpcf7mailsent', function( event ) {
            market = document.getElementById('market').value;
            help = document.getElementById('help').value;
            if(newsletteroptin.is(":checked") == true) {
                newsIsChecked = 'True';
            } else {
                newsIsChecked = 'False';
            }
            if(announcementOptinInput.is(":checked") == true) {
                announcementIsChecked = 'True';
            } else {
               announcementIsChecked = 'False';
            }
            window.dataLayer.push({
                "event" : 'Contact Us Form Submission',
                "formId" : event.detail.contactFormId,
                "repsonse" : event.detail.inputs,
                "eventCategory": 'Contact Us Form Submission',
                "eventAction": 'Contact Us Form Submission',
                "eventLabel": 'Market: ' + market + ' | How Can We Help: ' + help + ' | Newsletter Opt-in is checked: ' + newsIsChecked + ' | Periodic Emails Opt-in: ' + announcementIsChecked,
            })
                
        });
    });
};

if (gatedFormButton) {
    Array.from(gatedFormButton).forEach(element => {
        document.addEventListener( 'wpcf7mailsent', function( event ) {
            window.dataLayer.push({
                "event" : 'Gated Form Submission',
                "formId" : event.detail.contactFormId,
                "repsonse" : event.detail.inputs,
                "eventCategory": 'Gated Form Submission',
                "eventAction": 'Gated Form Submission',
                "eventLabel": 'Submit Form',
            })
        });
    });
};


if (insightsSeeMore) {
    
    Array.from(insightsSeeMore).forEach(element => {
        element.addEventListener('click', e => {
            const { dataset } = element;
            const event = dataset.gtmEvent || 'Insights Hub See More';
            const eventCategory = dataset.gtmCategory || dataset.gtmEvent || 'Insights Hub See More';
            const eventAction = dataset.gtmAction || e.target.textContent.trim();
            const eventLabel = dataset.gtmLabel || global.location.pathname;
            pushToDataLayer(e, event, eventCategory, eventAction, eventLabel);
        });
    });
}


const leftNavigation = document.getElementById('secondary');
if (leftNavigation) {
    const leftNavigationLinks = content.querySelectorAll('.left-page-navigation a'); 
    Array.from(leftNavigationLinks).forEach(element => {
        element.addEventListener('click', e => {
            const { dataset } = element;
            const event = dataset.gtmEvent || 'Left Navigation';
            const eventCategory = dataset.gtmCategory || dataset.gtmEvent || 'Left Navigation';
            const eventAction = dataset.gtmAction || e.target.textContent.trim();
            const eventLabel = dataset.gtmAction || e.target.textContent.trim();
            pushToDataLayer(e, event, eventCategory, eventAction, eventLabel);
        });
    });
}


if (stayInformed) {
    
    Array.from(stayInformed).forEach(element => {
        element.addEventListener('click', e => {
            const { dataset } = element;
            const event = dataset.gtmEvent || 'Insights Hub Article';
            const eventCategory = dataset.gtmCategory || dataset.gtmEvent || 'Insights Hub Article';
            const eventAction = dataset.gtmAction || e.target.textContent.trim();
            const eventLabel = dataset.gtmLabel || global.location.pathname;
            pushToDataLayer(e, event, eventCategory, eventAction, eventLabel);
        });
    });
}

if (signup) {
    
    Array.from(signup).forEach(element => {
        const newsletteroptin2 = $('#newsletteroptin');
        const announcementOptinInput2 = $('#cpkannouncementsoptin');
        let newsIsChecked2 = '';
        let announcementIsChecked2 = '';
        document.addEventListener( 'wpcf7mailsent', function( event ) {
            if($('#newsletteroptin').is(":checked") == true) {
                newsIsChecked2 = 'True';
            } else {
                newsIsChecked2 = 'False';
            }
            if($('#cpkannouncementsoptin').is(":checked") == true) {
                announcementIsChecked2 = 'True';
            } else {
                announcementIsChecked2 = 'False';
            }
            dataLayer.push({
                "event" : 'Sign Up Submission',
                "formId" : event.detail.contactFormId,
                "repsonse" : event.detail.inputs,
                "eventCategory": 'Sign Up Submission',
                "eventAction": 'CSign Up Submission',
                "eventLabel":  'Newsletter Opt-in is checked: ' + newsIsChecked2 + ' | Periodic Emails Opt-in: ' + announcementIsChecked2,
            })
        });
    });
}

if (stayInformedCategory) {
    
    Array.from(stayInformedCategory).forEach(element => {
        element.addEventListener('click', e => {
            const { dataset } = element;
            const event = dataset.gtmEvent || 'Insights Hub Category';
            const eventCategory = dataset.gtmCategory || dataset.gtmEvent || 'Insights Hub Article Category';
            const eventAction = dataset.gtmAction || e.target.textContent.trim();
            const eventLabel = dataset.gtmLabel || global.location.pathname;
            pushToDataLayer(e, event, eventCategory, eventAction, eventLabel);
        });
    });
}

/*if (links) {
    
    Array.from(links).forEach(element => {
        element.addEventListener('click', e => {
            const { dataset } = element;
            const event = dataset.gtmEvent || 'Link';
            const eventCategory = dataset.gtmCategory || dataset.gtmEvent || 'Link';
            const eventAction = dataset.gtmAction || e.target.textContent.trim();
            const eventLabel = dataset.gtmLabel || global.location.pathname;
            pushToDataLayer(e, event, eventCategory, eventAction, eventLabel);
        });
    });
}*/

const buttons = content.getElementsByClassName('btn:not(.load-more)');
if (buttons) {
    Array.from(buttons).forEach(element => {
        element.addEventListener('click', e => {
            const { dataset } = element;
            const event = dataset.gtmEvent || 'CTA Button';
            const eventCategory = dataset.gtmCategory || 'Button';
            const eventAction = dataset.gtmAction || e.target.textContent.trim();
            const eventLabel = dataset.gtmLabel || global.location.pathname;
            pushToDataLayer(e, event, eventCategory, eventAction, eventLabel);
        });
    });
}

const footer = document.getElementById('colophon');
if (footer) {
    const navLinks = footer.querySelectorAll('.menu-item a');
    Array.from(navLinks).forEach(navLink => {
        navLink.addEventListener('click', e => {
            const { dataset } = navLink;
            const event = dataset.gtmEvent || 'Footer';
            const eventCategory = dataset.gtmCategory || dataset.gtmEvent || 'Footer';
            const eventAction = dataset.gtmAction || e.target.textContent.trim();
            const eventLabel = dataset.gtmAction || e.target.textContent.trim();
            pushToDataLayer(e, event, eventCategory, eventAction, eventLabel);
        });
    });
}
import '../js/skip-link-focus-fix';
import '../js/tracking';

(function () {
    if ( typeof NodeList.prototype.forEach === "function" ) return false;
    NodeList.prototype.forEach = Array.prototype.forEach;
})();

//Prodcut-Detail pages content nav to show overview, properties, and compliance.
//Remove and add class to the product detail page content overview nav
const productDetailNavItems = document.querySelectorAll('.product-detail-nav-item');
const eventDetailNavItems = document.querySelectorAll('.event-detail-nav-item');

productDetailNavItems.forEach((element, index) => {
	element.addEventListener('click', e => {
		e.preventDefault();
		//remove the detail-active class from the list items
		const activeDetail = document.querySelector('.detail-active');
		activeDetail.classList.remove('detail-active');
		//remove the detail-content-active class from the related content divs
		const activeContentDetail= document.querySelector('.detail-content-active');
		activeContentDetail.classList.remove('detail-content-active');
		//add the active classes to the clicked list item, and it's related content div based on index
		const detailContent = document.querySelectorAll('.detail-content')[index];
		detailContent.classList.add('detail-content-active');
		e.target.classList.add('detail-active');
	})
})

eventDetailNavItems.forEach((element, index) => {
    element.addEventListener('click', e => {
        e.preventDefault();
        //remove the detail-active class from the list items
        const activeDetail = document.querySelector('.detail-active');
        activeDetail.classList.remove('detail-active');
        //remove the detail-content-active class from the related content divs
        const activeContentDetail= document.querySelector('.detail-content-active');
        activeContentDetail.classList.remove('detail-content-active');
        //add the active classes to the clicked list item, and it's related content div based on index
        const detailContent = document.querySelectorAll('.detail-content')[index];
        detailContent.classList.add('detail-content-active');
        e.target.classList.add('detail-active');
    })
})

$(window).on('resize', function () { AOS.refresh(); });
$(window).on('load', function() { setTimeout(AOS.refreshHard, 150); });
AOS.init({ 
   startEvent: 'load',
   easing: 'ease-in-out-quart', 
   duration: 600,  once: false            
});
window.addEventListener('load', AOS.refresh);

let scrollPosition = window.scrollY;
const fixedHeader = document.getElementById('fixed-header');

window.addEventListener('scroll', function() {

    scrollPosition = window.scrollY;

    if (scrollPosition >= 40) {
        fixedHeader.classList.add('fixed-position');
    } else {
        fixedHeader.classList.remove('fixed-position');
    }

});


//A simple script to prevent empty searches

$(document).ready(function(){        
    $('#searchform').submit(function(e) { // run the submit function, pin an event to it
        var s = $( this ).find("#s").val($.trim($( this ).find("#s").val())); // find the #s, which is the search input id and trim any spaces from the start and end
        if (!s.val()) { // if s has no value, proceed
            e.preventDefault(); // prevent the default submission
            
            $('#s').focus(); // focus on the search input
        }
    });

    


    //gated content form
    const triggerLink = '';
    if($('.trigger-modal').length) {
        $('.trigger-link-download').hide();
        $('.trigger-modal').on('click', function(e) {
            e.preventDefault();
            const triggerLink = $(this).attr('href');
            $('.modal-target-download').attr('href', triggerLink);
            $('#myModal').modal();
        });
    };
    $('.search-field').hide();
    $('.search-submit').on('click', function() {
        $('#searchform').toggleClass('search-form-on');
        $('.search-field').slideToggle();

    })

    //Contact 7 Sign Up form on home page - disable submit until one item is checked
    const signupformcontainer = document.getElementById('sign-up-260');
    const signupbutton = document.querySelectorAll('.cpk-signup')[0];
    const newsletteroptin = document.getElementById('newsletteroptin');
    const cpkannouncementsoptin = document.getElementById('cpkannouncementsoptin');
    const emailoptinvalidation = document.getElementById('emailOptInValidation');
    
    if(signupformcontainer) {
        signupbutton.setAttribute('disabled', true);

        newsletteroptin.addEventListener('input', () => {
            if (newsletteroptin.checked || cpkannouncementsoptin.checked) {
                emailoptinvalidation.style.display = 'none';
                signupbutton.removeAttribute('disabled');
            } else {
                emailoptinvalidation.style.display = 'block';
                signupbutton.setAttribute('disabled', true);
            };
        });
        cpkannouncementsoptin.addEventListener('input', () => {
            if (newsletteroptin.checked || cpkannouncementsoptin.checked) {
                emailoptinvalidation.style.display = 'none';
                signupbutton.removeAttribute('disabled');
            } else {
                emailoptinvalidation.style.display = 'block';
                signupbutton.setAttribute('disabled', true);
            };
        });
    };


    function setCookie(name, value, days) {
        const d = new Date;
        d.setTime(d.getTime() + 24*60*60*1000*days);
        document.cookie = name + "=" + value + ";path=/;secure;expires=" + d.toGMTString();
    };

    function getCookie(name) {
        const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    };


    $('.trigger-gated-form').on('click', function(e){
        e.preventDefault();
        let triggeredClick = $(this);
        $('.main-content').hide();
        let pdfLink = $(this).attr('href');
        const gateCookie = getCookie('gatedCompletedForm');
        if(gateCookie) {
            $('.related-content-gated-form').hide();
            $('.main-content').show();
            $('.related-pdf-gated-content-link').show();
            $('.related-pdf-gated-content-link').attr('href', pdfLink);
            window.open(pdfLink, '_blank');
        } else {
            $('.related-content-gated-form').show();
            $('.main-content').hide();
            document.addEventListener( 'wpcf7mailsent', function( event ) {
                if ( '983' == event.detail.contactFormId ) {
                    setCookie('gatedCompletedForm', 'true', 999);
                    $('.related-content-gated-form').hide();
                    $('.main-content').show();
                    $('.related-pdf-gated-content-link').show();
                    let getRelatedLink =  $('.related-pdf-gated-content-link a').attr('href', pdfLink);
                    window.open(pdfLink, '_blank');
                    
                }
            }, false );
        }
    });

    

    //Gate content with form 983
    if($('.content-hub-gated-form').length) {
        const gateCookie = getCookie('gatedCompletedForm');
        if(gateCookie) {
            $('.content-hub-gated-form').hide();
            $('.post-entry-content-gated').show();
            if($('.cpkelco-gated-pdf').length) {
                $('.cpkelco-gated-pdf')[0].click();
            }
        } else {

            $('.post-entry-content-gated').hide();
            document.addEventListener( 'wpcf7mailsent', function( event ) {
                
                if ( '983' == event.detail.contactFormId ) {
                    $('.post-entry-content-gated').show();
                    setCookie('gatedCompletedForm', 'true', 999);
                    $('.content-hub-gated-form').hide();
                    if($('.cpkelco-gated-pdf').length) {
                       $('.cpkelco-gated-pdf')[0].click();
                    }
                }
            }, false );
        }
    }
});



export default (e, event = 'Link', eventCategory = event, eventAction = e.target.textContent.trim(), eventLabel = global.location.pathname) => {
    try {
        const object = {
            event,
            eventCategory,
            eventAction,
            eventLabel
        };

        if (global.hasOwnProperty('dataLayer')) {
            global.dataLayer.push(object);
        } else {
            console.log(object);
        }
    } catch (error) {
        //DO nothing, analytics is important but cannot break anything.
    }
};